.table thead th,
.table tbody td {
  vertical-align: middle; /* Alinea el contenido verticalmente en el medio */
}

.table thead th.text-end,
.table tbody td.text-end {
  text-align: right; /* Alinea el contenido de la columna de acciones a la derecha */
}

.table tbody .d-flex {
  display: flex;
  justify-content: flex-end; /* Alinea los botones dentro de la celda a la derecha */
}
