.paragraph-table-overflow {
    overflow-x: auto;
    height: 40vh;
}

.evaluation-table-overflow {
    overflow-x: auto;
    height: 60vh;
}


.status {
    font-size: 20px;
}