.table-wrapper {
  max-height: 550px;
  /* Ajusta esto según la altura deseada */
  overflow-y: auto;
  /* Habilita el desplazamiento vertical */
}

.sticky-header th{
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  /* Asegura que el fondo no sea transparente */
}